import classNames from 'classnames';
import styles from './index.module.less';
import { UnorderedListOutlined } from '@ant-design/icons';
import { useRouter } from 'next/router';
import Image from 'next/image';

const MobileGlobalHeader = () => {
  const router = useRouter();
  const inAboutUsPage = router.asPath.includes('/about-us');
  return (
    <div
      className={classNames(
        'h-[44px] flex items-center justify-between pl-[20px] pr-[12px]',
        inAboutUsPage ? styles.mobileHeaderInAboutUs : styles.mobileHeader,
      )}
    >
      <Image
        src={inAboutUsPage ? '/footer-logo.svg' : '/header-logo.svg'}
        className="w-[84px] h-[20px]"
        alt=""
      />
      <div className="w-[40px] h-[40px] flex items-center justify-center">
        <UnorderedListOutlined className="text-[18px]" />
      </div>
    </div>
  );
};

export default MobileGlobalHeader;
