import Link from 'next/link';
import classNames from 'classnames';
import styles from './index.module.less';
import useScreenSizeStore from '@/zustand/screenSize';
import { Dropdown, Space } from 'antd';
import MobileGlobalHeader from '@/components/Layouts/GlobalHeader/MobileGlobalHeader';
import { useRouter } from 'next/router';
import { ChevronDown } from '@metro/icons';

const GlobalHeader = () => {
  const isMobile = useScreenSizeStore((state) => state.isMobile());
  const router = useRouter();
  const inAboutUsPage = router.asPath.includes('/about-us');
  const inHomePage = router.asPath === '/';
  if (isMobile) {
    return <MobileGlobalHeader />;
  }
  return (
    <header
      className={classNames(
        inAboutUsPage
          ? styles.globalHeaderInAboutUs
          : inHomePage
            ? styles.globalHeaderInHome
            : styles.globalHeader,
      )}
    >
      <Link href={'/'}>
        <div className={styles.logo}></div>
      </Link>
      <div className={classNames(styles.menu, 'mr-[11.11vw]')}>
        <div className="flex-1"></div>
        <ul>
          <li>
            <Link href={'/'} className={classNames(styles.text)}>
              首页
            </Link>
          </li>
          <li>
            {/*<Link href={'/#showcases'} className={classNames(styles.text)}>*/}
            {/*</Link>*/}
            <Dropdown
              placement="bottom"
              menu={{
                items: [
                  {
                    key: '1',
                    label: '脉策城市地图',
                    onClick: () => {
                      router.push('/city-map');
                    },
                  },
                  {
                    key: '2',
                    label: '脉策市民云',
                    onClick: () => {
                      router.push('/citizen-cloud');
                    },
                  },
                ],
              }}
            >
              <div className={classNames(styles.text, 'cursor-pointer')}>
                产品 <ChevronDown />
              </div>
            </Dropdown>
          </li>
          <li>
            <Link href={'/chatmdt'} className={classNames(styles.text)}>
              人工智能
            </Link>
          </li>
          <li>
            <Link href={'/about-us'} className={classNames(styles.text)}>
              关于我们
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default GlobalHeader;
