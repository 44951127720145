import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { GlobalHeader, GlobalFooter } from '@/components/Layouts';
import { useConfigProvider } from '@/context/ConfigProvider';
import classNames from 'classnames';
import { useToggle } from 'react-use';
import './index.less';

interface LayoutProps {
  /**
   * Please use next/head
   */
  head?: React.ReactNode;
  children: React.ReactNode;
}

// export async function getServerSideProps() {
//   const res = await fetch(
//     `https://api.pietrastudio.com/commons/business-settings/DISPLAY/CATEGORY_NAV_DROPDOWN_ITEMS`,
//   );
//   console.log('res:', res);
//   return {
//     categoryNavDropdownItems: [],
//   };
// }
const Layout = ({ children, head }: LayoutProps) => {
  const { pathname } = useRouter();
  const { isMobile } = useConfigProvider();
  const [open, toggle] = useToggle(false);
  const ref = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref.current.scrollTop = 0;
  }, [pathname]);

  return (
    <div className={'layout'} ref={ref}>
      {head}
      <header className={'header'}>
        <GlobalHeader />
      </header>
      <main
        className={classNames('main', {
          mobile: isMobile,
        })}
      >
        {children}
      </main>
      <footer className={'footer'}>
        <GlobalFooter />
      </footer>
    </div>
  );
};
export default Layout;
