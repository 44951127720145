import dayjs from 'dayjs';

const IpcContent = () => {
  const year = dayjs().year() || '2024';
  const jump = () => {
    window.open('https://beian.miit.gov.cn/');
  };
  return (
    <div className="flex items-center flex-wrap gap-[12px] text-[12px] text-white text-opacity-60">
      <div className="break-keep">©{year} 上海脉策数据科技有限公司</div>
      <div className="cursor-pointer" onClick={jump}>
        沪ICP备15037556号-2
      </div>
      <div>沪公网安备31011002003926号</div>
    </div>
  );
};

export default IpcContent;
