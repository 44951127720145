import { useRouter } from 'next/router';
import IpcContent from '@/components/Layouts/GlobalFooter/ipc-content';

const MobilGlobalFooter = () => {
  const router = useRouter();
  const jumpChatMDT = () => {
    router.push('/chatmdt');
  };
  const jumpCitizenCloud = () => {
    router.push('/citizen-cloud');
  };
  const jumpCityMap = () => {
    router.push('/city-map');
  };
  return (
    <div className="bg-[#0E121C] pt-[20px] pl-[20px] pb-[40px] flex flex-col">
      <img src="/footer-logo.svg" className="w-[84px] h-[20px] mb-[36px]" alt="" />
      <div className="text-[14px] font-medium text-white mb-[16px]">产品</div>
      <div className="text-[14px] font-medium text-[#6383F7] mb-[16px]" onClick={jumpCityMap}>
        脉策城市地图
      </div>
      <div className="text-[14px] font-medium text-[#6383F7]" onClick={jumpCitizenCloud}>
        脉策市民云
      </div>
      <div className="text-[14px] font-medium text-white mb-[16px] mt-[32px]">人工智能</div>
      <div className="text-[14px] font-medium text-[#6383F7]" onClick={jumpChatMDT}>
        ChatMDT
      </div>
      <div className="text-[14px] font-medium text-white mb-[16px] mt-[32px]">联系我们</div>
      <div className="text-[14px] text-[rgba(255,255,255,0.8)] mb-[8px]">
        市场合作：business@metrodata.cn
      </div>
      <div className="text-[14px] text-[rgba(255,255,255,0.8)]">
        地址：上海市杨浦区天盛广场C座10层
      </div>

      <div className="text-[14px] text-[rgba(255,255,255,0.8)] mt-[32px]">
        关注脉策公众号，了解更多最新动态；
        <br />
        添加企业微信，了解最前沿科技和产品。
      </div>

      <div className="flex items-center gap-[20px] mt-[12px] mb-[12px]">
        <div className="flex flex-col items-center gap-[4px]">
          <img className="w-[76px] h-[76px] object-cover" src="/qr/qr-01.png" alt="" />
          <div className="text-[13px] font-medium text-[rgba(255,255,255,0.8)]">脉策科技</div>
        </div>
        <div className="flex flex-col items-center gap-[4px]">
          <img className="w-[76px] h-[76px] object-cover" src="/qr/qr-02.png" alt="" />
          <div className="text-[13px] font-medium text-[rgba(255,255,255,0.8)]">城市数据团</div>
        </div>
        <div className="flex flex-col items-center gap-[4px]">
          <img className="w-[76px] h-[76px] object-cover" src="/qr/qr-03.png" alt="" />
          <div className="text-[13px] font-medium text-[rgba(255,255,255,0.8)]">企业微信</div>
        </div>
      </div>
      <IpcContent />
    </div>
  );
};

export default MobilGlobalFooter;
