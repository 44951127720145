import Link from 'next/link';
import styles from './index.module.less';
import classNames from 'classnames';
import useScreenSizeStore from '@/zustand/screenSize';
import MobilGlobalFooter from '@/components/Layouts/GlobalFooter/MobilGlobalFooter';
import { useRouter } from 'next/router';
import IpcContent from '@/components/Layouts/GlobalFooter/ipc-content';

const GlobalFooter = ({ show = true, className }: { show?: boolean; className?: string }) => {
  const isMobile = useScreenSizeStore((state) => state.isMobile());
  const isTablet = useScreenSizeStore((state) => state.isTablet());
  const router = useRouter();
  const jumpChatMDT = () => {
    router.push('/chatmdt');
  };
  const jumpCitizenCloud = () => {
    router.push('/citizen-cloud');
  };
  if (!show) {
    return <></>;
  }
  if (isMobile) {
    return <MobilGlobalFooter />;
  }
  return (
    <div className={styles.globalFooterWarp}>
      <div
        className={classNames(styles.content, {
          '!ml-[60px]': isTablet,
          '!mr-[60px]': isTablet,
          '!pb-[50px]': isTablet,
          '!pt-[50px]': isTablet,
        })}
      >
        <div
          className={classNames(styles.main, {
            '!flex-col': isTablet,
          })}
        >
          <div
            className={classNames(styles.left, {
              ['mb-[67px]']: isTablet,
            })}
          >
            <div className={styles.logo}>
              <img src="/footer-logo.svg" alt="logo" />
            </div>
          </div>
          <div className={classNames(styles.right, {})}>
            <div
              className={classNames(styles.aboutus, {
                'flex-1': isTablet,
              })}
            >
              <div className={styles.title}>产品</div>
              <div className={styles.list}>
                <div className={styles.item}>
                  <Link href="/city-map">脉策城市地图</Link>
                </div>
                <div className={styles.item}>
                  <Link href="/citizen-cloud">脉策市民云</Link>
                </div>
              </div>
            </div>
            <div
              className={classNames(styles.resources, {
                'flex-1': isTablet,
              })}
            >
              <div className={styles.title}>人工智能</div>
              <div className={styles.list}>
                <div className={styles.item}>
                  <Link href="/chatmdt">ChatMDT</Link>
                </div>
              </div>
            </div>
            <div
              className={classNames(styles.resources, {
                'flex-1': isTablet,
              })}
            >
              <div className={styles.title}>联系我们</div>
              <div className={styles.list}>
                <div className={styles.item}>市场合作：business@metrodata.cn</div>
                <div className={styles.item}>地址：上海市杨浦区天盛广场C座10层</div>
                <div className={classNames(styles.item, 'mt-[32px] !mb-[12px]')}>
                  关注脉策公众号，了解更多最新动态；
                  <br />
                  添加企业微信，了解最前沿科技和产品。
                </div>
                <div className="flex items-center gap-[20px]">
                  <div className="flex flex-col items-center gap-[4px]">
                    <img
                      className="w-[76px] h-[76px] object-cover"
                      src="/qr/qr-01.png"
                      alt=""
                      loading="lazy"
                    />
                    <div className="text-[13px] font-medium text-[rgba(255,255,255,0.8)]">
                      脉策科技
                    </div>
                  </div>
                  <div className="flex flex-col items-center gap-[4px]">
                    <img
                      className="w-[76px] h-[76px] object-cover"
                      src="/qr/qr-02.png"
                      alt=""
                      loading="lazy"
                    />
                    <div className="text-[13px] font-medium text-[rgba(255,255,255,0.8)]">
                      城市数据团
                    </div>
                  </div>
                  <div className="flex flex-col items-center gap-[4px]">
                    <img
                      className="w-[76px] h-[76px] object-cover"
                      src="/qr/qr-03.png"
                      alt=""
                      loading="lazy"
                    />
                    <div className="text-[13px] font-medium text-[rgba(255,255,255,0.8)]">
                      企业微信
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <IpcContent />
        {/*<div className="text-white">123</div>*/}
      </div>
    </div>
  );
};

export default GlobalFooter;
